
import { Navigation } from "@/mixins/navigation";
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class ArticleTitle extends mixins(Navigation) {
  @Prop({}) title!: string;
  @Prop({}) time!: string;
  @Prop({}) level!: string;
}
