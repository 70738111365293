var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.resources !== null)?_c('v-container',{staticClass:"my-6"},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center align-center"},[(
          _vm.topic == _vm.$constants.SUPPORT.TOPIC.SUMMARY_DASHBOARD &&
          _vm.resource == _vm.$constants.SUPPORT.RESOURCE.STATISTICS
        )?_c('Statistics'):_vm._e(),(
          _vm.topic == _vm.$constants.SUPPORT.TOPIC.SUMMARY_DASHBOARD &&
          _vm.resource == _vm.$constants.SUPPORT.RESOURCE.STORAGE_SPACE
        )?_c('StorageSpace'):_vm._e(),(
          _vm.topic == _vm.$constants.SUPPORT.TOPIC.SUMMARY_DASHBOARD &&
          _vm.resource == _vm.$constants.SUPPORT.RESOURCE.OTHERS
        )?_c('Others'):_vm._e()],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }