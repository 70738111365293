
import { Navigation } from "@/mixins/navigation";
import Component, { mixins } from "vue-class-component";
import ArticleTitle from "@/components/Support/ArticleTitle.vue";
import ArticleNavigation from "@/components/Support/ArticleNavigation.vue";

@Component({
  components: {
    ArticleTitle,
    ArticleNavigation,
  },
})
export default class Others extends mixins(Navigation) {}
