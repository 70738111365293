
import { Navigation } from "@/mixins/navigation";
import Component, { mixins } from "vue-class-component";
import { StyleCheck } from "@/mixins/style-check";
import PageTitle from "@/components/General/PageTitle.vue";
import Statistics from "./StaffTopics/Statistics.vue";
import StorageSpace from "./StaffTopics/StorageSpace.vue";
import Others from "./StaffTopics/Others.vue";
import { Watch } from "vue-property-decorator";

@Component({
  components: {
    PageTitle,
    Statistics,
    StorageSpace,
    Others,
  },
})
export default class StaffResource extends mixins(Navigation, StyleCheck) {
  resource = "";
  topic = "";

  resources: any = null;
  private async getResources() {
    await fetch(process.env.VUE_APP_HELP_RESOURCES, { mode: "cors" })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.resources = data;
      });
  }

  private async created() {
    await this.getResources();
  }

  @Watch("$route.params")
  private routeHandler() {
    this.resource = this.$route.params.resource;
    this.topic = this.$route.params.topic;
    window.scrollTo(0, 0);
  }

  private mounted() {
    this.resource = this.$route.params.resource;
    this.topic = this.$route.params.topic;
  }
}
